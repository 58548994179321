<template>
  <ul class="menu-nav">
    <template v-for="(row, index) in MenuList">
      <router-link
        v-bind:key="index"
        v-bind:to="row.route"
        v-bind:class="{
          'menu-item-active': isRouteActive(row.menu),
          'disable-link': disableLink(row.menu)
        }"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
          <a :href="href" class="menu-link" v-on:click="navigate">
            <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + row.icon + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            <span
              class="menu-text"
              v-if="row.menu != 'dashboard' && row.menu != 'calendar'"
              >{{ getMenuTitle(row.title) }}</span
            >
            <span class="menu-text" v-else>{{ row.title }}</span>
          </a>
        </li>
      </router-link>
    </template>
  </ul>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import inflect from "i";

export default {
  name: "KTMenu",
  data() {
    return {
      MenuList: []
    };
  },
  methods: {
    getMenuTitle(title) {
      return inflect().pluralize(title);
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }
      return false;
    },
    disableLink(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return (
          this.$route.meta.menuActive === match && this.$route.meta.isListing
        );
      }
      return false;
    },
    getMenuList() {
      const _this = this;
      try {
        _this.MenuList = JwtService.menuList();
        if (_this.lodash.isEmpty(_this.MenuList)) {
          JwtService.destroyToken();
        }
      } catch (error) {
        _this.logError(error);
        JwtService.destroyToken();
      }
    }
  },
  mounted() {
    this.getMenuList();
  }
};
</script>
